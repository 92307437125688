import './style.css'
import * as dat from 'lil-gui'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import firefliesVertexShader from './shader/vertex.glsl'
import firefliesFragmentShader from './shader/fragment.glsl'
import {gsap} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TorusGeometry } from 'three'
const htmlBody = document.querySelector('html')


//double click to resize
window.addEventListener('dblclick', ()=> 
{
    if(!document.fullscreenElement)
    {
        htmlBody.requestFullscreen();
    }
    else
    {
        document.exitFullscreen();
    }
}
)

/**
 * Dishable right click
 */
 window.addEventListener('contextmenu', function (e) { 
    e.preventDefault(); 
  }, false);


/**
 * Sizes
*/
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

/**
 * Initiale camera postion
 */
const cameraPosition = {
        x :  1.118,
        y :  3.4,
        z :  -0.0118,
}


/**
 * Distance
 */
const distance = 3.185 - 2.9

/**
* Cursor
*/
const cursor = {}
cursor.y = 0;
cursor.z = 0;
cursor.xClient = 0;
cursor.yClient = 0;

window.addEventListener('mousemove', (event) => 
{
    cursor.y = (event.clientX / sizes.width) - 0.5;
    cursor.z = (event.clientY / sizes.height) - 0.5;
    cursor.xClient = event.clientX;
    cursor.yClient = event.clientY;
})






// /**
// * Base
// */
// // Debug
// const gui = new dat.GUI({
//     width: 200
// })


// Canvas
const canvas = document.querySelector('canvas.webgl')



// Scene
const scene = new THREE.Scene()

/**
 * Loaders
*/
// Texture loader
const textureLoader = new THREE.TextureLoader()

// Draco loader
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('draco/')

// GLTF loader
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

/**
 * Preloading page 
 */

window.addEventListener('load', () =>
 {
     const prelodingPage = document.querySelector(".preloadingPageDiv")
     prelodingPage.classList.add('hidden')
 })

/**
 * Texture
*/
const bakeTexture = textureLoader.load('bake.jpg')
bakeTexture.flipY = false
bakeTexture.encoding = THREE.sRGBEncoding

//Defaut screen material
let bakeScreenTexture = textureLoader.load('zatpay.jpg')
bakeScreenTexture.flipY = true
bakeScreenTexture.encoding = THREE.sRGBEncoding
//Loading Portoflio texture
let bakeScreenTexture1 = textureLoader.load('portfolio.jpg')
bakeScreenTexture1.flipY = true
bakeScreenTexture1.encoding = THREE.sRGBEncoding
//Loading Zatpay web texture
let bakeScreenTexture2 = textureLoader.load('zatpayWeb.jpg')
bakeScreenTexture2.flipY = true
bakeScreenTexture2.encoding = THREE.sRGBEncoding

//Loading Zatpay App texture
let bakeScreenTexture3 = textureLoader.load('zatpayApp.jpg')
bakeScreenTexture3.flipY = true
bakeScreenTexture3.encoding = THREE.sRGBEncoding

//Loading Zatpay Dashboard texture
let bakeScreenTexture4 = textureLoader.load('zatpayDashboard.jpg')
bakeScreenTexture4.flipY = true
bakeScreenTexture4.encoding = THREE.sRGBEncoding

//Loading 3D texture
let bakeScreenTexture5 = textureLoader.load('3D.jpg')
bakeScreenTexture5.flipY = true
bakeScreenTexture5.encoding = THREE.sRGBEncoding

//Loading Giovanni Agency Web texture
let bakeScreenTexture6 = textureLoader.load('giovanniAgency.jpg')
bakeScreenTexture6.flipY = true
bakeScreenTexture6.encoding = THREE.sRGBEncoding

//Loading Palm Auto Texture
let bakeScreenTexture7 = textureLoader.load('palmAutoWeb.jpg')
bakeScreenTexture7.flipY = true
bakeScreenTexture7.encoding = THREE.sRGBEncoding

//Loading Palm Auto 2 Texture
let bakeScreenTexture8 = textureLoader.load('palmAuto.jpg')
bakeScreenTexture8.flipY = true
bakeScreenTexture8.encoding = THREE.sRGBEncoding

//Loading Enac IHM Texture
let bakeScreenTexture9 = textureLoader.load('enacIHM.jpg')
bakeScreenTexture9.flipY = true
bakeScreenTexture9.encoding = THREE.sRGBEncoding

//Loading Particules JS animation texture
let bakeScreenTexture10 = textureLoader.load('particulesHoverAnimation.jpg')
bakeScreenTexture10.flipY = true
bakeScreenTexture10.encoding = THREE.sRGBEncoding


//Loading Particules JS animation texture
let bakeScreenTexture11 = textureLoader.load('particulesImages.jpg')
bakeScreenTexture11.flipY = true
bakeScreenTexture11.encoding = THREE.sRGBEncoding

//Loading PHuman pose tracking JS animation texture
let bakeScreenTexture12 = textureLoader.load('bodyDetection.jpg')
bakeScreenTexture12.flipY = true
bakeScreenTexture12.encoding = THREE.sRGBEncoding

//Loading Particules JS animation texture
let bakeScreenTexture13 = textureLoader.load('faceDetection.jpg')
bakeScreenTexture13.flipY = true
bakeScreenTexture13.encoding = THREE.sRGBEncoding

//Loading Pqt5 texture
let bakeScreenTexture14 = textureLoader.load('pyqt.jpg')
bakeScreenTexture14.flipY = true
bakeScreenTexture14.encoding = THREE.sRGBEncoding

let bakeScreenTexture15 = textureLoader.load('shaders.jpg')
bakeScreenTexture15.flipY = true
bakeScreenTexture15.encoding = THREE.sRGBEncoding

//Sleek Chocie plugin 
let backSreenTexturePlugin = textureLoader.load('sleekChoice.jpg')
backSreenTexturePlugin.flipY = true
backSreenTexturePlugin.encoding = THREE.sRGBEncoding

//smakoe Grope
let backScreenTextureSmako = textureLoader.load('smakoGroupe.jpg')
backScreenTextureSmako.flipY = true
backScreenTextureSmako.encoding = THREE.sRGBEncoding

//YocApp
let backScreenYocApp = textureLoader.load('yocFood.jpg')
backScreenYocApp.flipY = true
backScreenYocApp.encoding = THREE.sRGBEncoding

//Dynamic Island
let backScreenDynamic = textureLoader.load('Island.jpg')
backScreenDynamic.flipY = true
backScreenDynamic.encoding = THREE.sRGBEncoding





/**
 * Backed material
*/
const bakeMaterial = new THREE.MeshBasicMaterial({ map: bakeTexture})
const bakeScreenMaterial = new THREE.MeshBasicMaterial({ map: bakeScreenTexture})
const bakeScreenMaterial1 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture1})
const bakeScreenMaterial2 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture2})
const bakeScreenMaterial3 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture3})
const bakeScreenMaterial4 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture4})
const bakeScreenMaterial5 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture5})
const bakeScreenMaterial6 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture6})
const bakeScreenMaterial7 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture7})
const bakeScreenMaterial8 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture8})
const bakeScreenMaterialPlugin = new THREE.MeshBasicMaterial({ map: backSreenTexturePlugin})
const backScreenMaterialSmako = new THREE.MeshBasicMaterial({ map: backScreenTextureSmako})



//another section
const bakeScreenMaterial9 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture9})
const bakeScreenMaterial10 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture10})
const bakeScreenMaterial11 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture11})
const bakeScreenMaterial12 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture12})
const bakeScreenMaterial13 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture13})
const bakeScreenMaterial14 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture14})
const bakeScreenMaterial15 = new THREE.MeshBasicMaterial({ map: bakeScreenTexture15})
const bakeScreenMaterialYocApp = new THREE.MeshBasicMaterial({ map: backScreenYocApp})
const bakeScreenMaterialDynamic = new THREE.MeshBasicMaterial({ map: backScreenDynamic})




/**
 * Pole light material
 */
const poleLightMeterial = new THREE.MeshBasicMaterial({color: 0xffffff})


/**
 * Model
 */
gltfLoader.load(
    'deskScene.glb',
    (gltf) =>
    {
        gltf.scene.traverse((child) => 
        {
            child.material = bakeMaterial
        })
        const droneCenterLightMesh  = gltf.scene.children.find(child => child.name==='Sphere')
        const ecranMesh = gltf.scene.children.find(child => child.name==="screen" )
        // console.log(ecranMesh)

        droneCenterLightMesh.material = poleLightMeterial
        // ecranMesh.material = bakeScreenMaterial

        gltf.scene.rotation.z =  - Math.PI/20
        scene.add(gltf.scene)
    }
)

// gltfLoader.load(
//     'screen.glb',
//     (gltf) =>
//     {
        
//         gltf.scene.traverse((child) => 
//         {
//             child.material = bakeScreenMaterial
//         })
//         gltf.scene.rotation.z =  - Math.PI/20
//         scene.add(gltf.scene)
//     }
// )

/**
 * Changing the loading texture
 */


let linkLabPro = Array.from(document.querySelectorAll(".singleline"));

gltfLoader.load(
    'screen.glb',
    (gltf) =>
    {   
        gltf.scene.traverse((child) => 
        {        
            child.material = bakeScreenMaterial
            linkLabPro.forEach(lien => {
                lien.addEventListener('mouseover', () =>
                {
                    const idOfElementPorfolio = lien.getAttribute('id');  //Get the Dom ELement attribute
                    switch(idOfElementPorfolio) {
                        case "portfolio":
                            child.material = bakeScreenMaterial1
                            break
                        case "zatpayWebsite":
                            child.material = bakeScreenMaterial2
                            break
                        case "zatpayApp":
                            child.material = bakeScreenMaterial3
                            break
                        case "zatpayDashboard":
                            child.material = bakeScreenMaterial4
                            break
                        case "3Dtitre":
                            child.material = bakeScreenMaterial5
                            break
                        case "giovanniAgency":
                            child.material = bakeScreenMaterial6
                            break
                        case "palmautoWebsite":
                            child.material = bakeScreenMaterial7
                            break
                        case "palmautoDashboard":
                            child.material = bakeScreenMaterial8
                            break
                        case "enacIHM":
                            child.material = bakeScreenMaterial9
                            break
                        case "particulesHoverAnimation":
                            child.material = bakeScreenMaterial10
                            break
                        case "particulesImages":
                            child.material = bakeScreenMaterial11
                            break
                        case "bodyDetection":
                            child.material = bakeScreenMaterial12
                            break
                        case "faceDetection":
                            child.material = bakeScreenMaterial13
                            break
                        case "pyqt":
                            child.material = bakeScreenMaterial14
                            break
                        case "shaders":
                            child.material = bakeScreenMaterial15
                            break
                        case "sleekChoicePlugin":
                            child.material = bakeScreenMaterialPlugin
                            break
                        case "creativeLanding":
                            child.material = backScreenMaterialSmako
                            break
                        case "YocApp":
                            child.material = bakeScreenMaterialYocApp
                            break
                        case "dynamic":
                            child.material = bakeScreenMaterialDynamic
                            break
                    }
                    // if (idOfElementPorfolio==="portfolio")
                    // {
                    //     console.log("c'est bon")
                    //     child.material = bakeScreenMaterial1
                    // }
                    // if (idOfElementPorfolio==="zatpayWebsite")
                    // {
                    //     console.log("C'est bon")
                    // }
                })
                lien.addEventListener('mouseout', () =>
                {
                    child.material = bakeScreenMaterial
                })
            })
        })
        gltf.scene.rotation.z =  - Math.PI/20
        scene.add(gltf.scene)
    }
)



// link.forEach(lien => {
//      lien.addEventListener('mouseover', () =>
//          {
//          console.log("hover")
//          gltfLoader.load(
//             'screen.glb',
//             (gltf) =>
//             {
                
//                 gltf.scene.traverse((child) => 
//                 {
//                     child.material = bakeScreenMaterial1
//                 })
//                 gltf.scene.rotation.z =  - Math.PI/20
//                 scene.add(gltf.scene)
//             }
//         )
//          })
//      lien.addEventListener('mouseleave', () =>
//      {
//          console.log("leave")
//      })
//  })

/**
 * FireFlies
 */
const firefliesGeometry = new THREE.BufferGeometry()
const firefliesCount = 300
const positionArray = new Float32Array(firefliesCount * 3)
const scaleArray = new Float32Array(firefliesCount)
 
for (let i = 0 ; i < firefliesCount ; i++)
{
     positionArray[ i * 3 + 0] = Math.random() * 10 - 6
     positionArray[ i * 3 + 1] = Math.random() * 3  + 1.5
     positionArray[ i * 3 + 2] = Math.random() * 10  - 8
     scaleArray[i] = Math.random()
}
firefliesGeometry.setAttribute('position', new THREE.BufferAttribute(positionArray , 3))
firefliesGeometry.setAttribute('aScale', new THREE.BufferAttribute(scaleArray, 1))

//Material for firefliesGeometry
const firefliesMaterial  = new THREE.ShaderMaterial({
    transparent: true,
    uniforms:
    {
        uTime:{ value: 0},
        uPixelRatio: { value: Math.min(window.devicePixelRatio, 2) }, 
        uSize: { value: 100 }
    },
    vertexShader : firefliesVertexShader,
    fragmentShader : firefliesFragmentShader,
    blending: THREE.AdditiveBlending,
    depthWrite: false,
})

// gui.add(firefliesMaterial.uniforms.uSize, 'value').min(1).max(500).step(1).name('firefliesSize')

//Points
const fireflies = new THREE.Points(firefliesGeometry, firefliesMaterial)
scene.add(fireflies)




window.addEventListener('resize', () =>
{

    console.log('resize')
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    firefliesMaterial.uniforms.uPixelRatio.value = Math.min(window.devicePixelRatio, 2)
})

/**
 * Grabbing the scroll element indicator 
 */
const scrollElement = document.getElementById("scrollToNavigate")
const scrollElementText = document.getElementById("scrollView")


/**
 * Scroll
 */
let scrollY = window.scrollY
window.addEventListener('scroll', () =>
{
    scrollY = window.scrollY
    // camera.position.x += 0.01
    // camera.position.y -= 0.001
    if (scrollY > 250)
    {
        scrollElement.style.display = 'none';
        scrollElementText.style.display = "none";
    }
    if (scrollY <= 250)
    {
        scrollElement.style.display = 'flex';
        scrollElementText.style.display = "flex";
    }
})





/**
 * Camera
*/
//Camera groupe
const cameraGroupe = new THREE.Group()
// cameraGroupe.position.x =   cameraPosition.x
// cameraGroupe.position.y = cameraPosition.y
// cameraGroupe.position.z =  cameraPosition.z

scene.add(cameraGroupe)


// Base camera
const camera = new THREE.PerspectiveCamera(40, sizes.width / sizes.height, 0.1, 1000)
camera.position.x =   cameraPosition.x
camera.position.y = cameraPosition.y
camera.position.z =  cameraPosition.z
camera.rotation.y = Math.PI / 2




// camera.lookAt(scene.position);
cameraGroupe.add(camera)

// const cameraFolder = gui.addFolder('Camera')
// cameraFolder.add(camera.position, 'x', -3, 3).step(0.001)
// cameraFolder.add(camera.position, 'y', -5, 5).step(0.001)
// cameraFolder.add(camera.position, 'z', -3, 3).step(0.001)


// Controls
// const controls = new OrbitControls(camera, canvas)


/**
 * Renderer
*/
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha:true,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding

/**
 * Animate
*/
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    camera.position.y = cameraPosition.y - (scrollY/sizes.height) * distance
    camera.position.x = cameraPosition.x + (scrollY/sizes.height) * 1.2
    camera.position.z = cameraPosition.z - (scrollY/sizes.height) * 0.3

    // Update controls
    // controls.update()

    const parallaxY = cursor.y * 0.5
    const parallaxZ = -cursor.z * 0.5
    cameraGroupe.position.y += (parallaxY  - cameraGroupe.position.y ) * 0.04
    cameraGroupe.position.z += (parallaxZ  - cameraGroupe.position.z ) * 0.04
    cameraGroupe.rotation.y += (-parallaxY - cameraGroupe.rotation.y) * 0.06

    //Update fireflies animations
    firefliesMaterial.uniforms.uTime.value = elapsedTime * 0.2


    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()


/**
 *Hover Javascript on icon 
 */

const mouseInfoDiv = document.querySelector('.mouseInfo')
const iconDomArary = Array.from(document.querySelectorAll("div.technologyUsedDiv > a"))
const iconMotionDesignDomArary = Array.from(document.querySelectorAll(".block > a"))
const iconTotalArray = iconDomArary.concat(iconMotionDesignDomArary)

iconTotalArray.forEach(icon => 
{   


    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
        icon.addEventListener('touchstart', () => 
        {
            //Get the coordinate of the rectangle and the id of the Dom elements
            let rect = icon.getBoundingClientRect();
            let idName = icon.getAttribute('id');
    
            //set the new dom element 
            mouseInfoDiv.style.display = "inline-block";
            mouseInfoDiv.style.left = `${rect.left - 15}px`;
            mouseInfoDiv.style.top = `${rect.top - 42}px` ;
            mouseInfoDiv.innerHTML = `<p style="font-size:1.6vmin; margin: 11px 12px 10px 12px; color:white;">${idName}</p>`;
        })
        icon.addEventListener('touchend', () => 
        {
            mouseInfoDiv.style.display = "none";
        })
        // some code..
    }
    else
    {
        icon.addEventListener('mouseover', () => 
        {
            //Get the coordinate of the rectangle and the id of the Dom elements
            let rect = icon.getBoundingClientRect();
            let idName = icon.getAttribute('id');
    
            //set the new dom element 
            mouseInfoDiv.style.display = "inline-block";
            mouseInfoDiv.style.left = `${rect.left - 20}px`;
            mouseInfoDiv.style.top = `${rect.top - 55}px` ;
            mouseInfoDiv.innerHTML = `<p style="font-size:1.3vmin; margin: 11px 12px 10px 12px; color:white;">${idName}</p>`;
        })
        icon.addEventListener('mouseleave', () => 
        {
            mouseInfoDiv.style.display = "none";
        })
    }
})


function hasTouch() {
    return 'ontouchstart' in document.documentElement
           || navigator.maxTouchPoints > 0
           || navigator.msMaxTouchPoints > 0;
  }
  
  if (hasTouch()) { // remove all the :hover stylesheets
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si];
        if (!styleSheet.rules) continue;
  
        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue;
  
          if (styleSheet.rules[ri].selectorText.match(':hover')) {
            styleSheet.deleteRule(ri);
          }
        }
      }
    } catch (ex) {}
  }
